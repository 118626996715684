import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 0;
`

export const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 24px;
`
