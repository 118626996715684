import api from '../axios'

const register = async ({ name, email, password, passwordConfirm, role }) => {
  try {
    const resp = await api.post('/auth/partner/register', { name, email, password, passwordConfirm, role })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const login = async ({ email, password }) => {
  try {
    const resp = await api.post('/auth/partner/login', { email, password })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const forgotPassword = async ({ email }) => {
  try {
    const resp = await api.post('/auth/partner/forgotPassword', { email })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const resetPassword = async ({ token, password, passwordConfirm }) => {
  try {
    const resp = await api.patch('/auth/partner/resetPassword/' + token, { password, passwordConfirm })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}

const auth = { register, login, forgotPassword, resetPassword }
export default auth
