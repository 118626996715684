import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import profileReducer from './profile'
import couponReducer from './coupon'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    coupon: couponReducer,
  },
})
