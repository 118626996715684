import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LogoImg = styled.img`
  width: ${(props) => props.width || '100%'};
  aspect-ratio: 1;
  object-fit: contain;
`

const Logo = ({ width }) => {
  return <LogoImg src="/logo.png" width={width} alt="VersaVid" />
}

Logo.propTypes = {
  width: PropTypes.number,
}

export default Logo