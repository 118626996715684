import api from '../axios'

const getProfile = async () => {
  try {
    const resp = await api.get('/profile/partner')
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const updateProfile = async ({ name, email }) => {
  try {
    const resp = await api.patch('/profile/partner', { name, email })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const updatePassword = async ({ passwordCurrent, password, passwordConfirm }) => {
  try {
    const resp = await api.patch('/profile/partner/updatePassword', { passwordCurrent, password, passwordConfirm })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const deleteAccount = async () => {
  try {
    const resp = await api.delete('/profile/partner')
    return resp.data
  } catch (error) {
    return error.response.data
  }
}

const profile = { getProfile, updateProfile, updatePassword, deleteAccount }
export default profile
