import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../layouts/dashboard'
import { selectIsLoading, selectProfile, updateProfile } from '../../stores/profile'
import ProfileForm from './ProfileForm'

const ProfileContainer = () => {
  const isLoading = useSelector(selectIsLoading)
  const profile = useSelector(selectProfile) || {}
  const dispatch = useDispatch()

  const onSubmit = (payload) => dispatch(updateProfile(payload))

  return (
    <DashboardLayout>
      <ProfileForm isLoading={isLoading} profile={profile} onSubmit={onSubmit} />
    </DashboardLayout>
  )
}

export default ProfileContainer
