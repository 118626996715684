import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, Button, Spinner, Icon } from '@blueprintjs/core'
import { AvatarContainer, AvatarOverlay, AvatarWrapper, Container, FormTitle, ProfileCard } from './ProfileForm.styled'
import { DEFAULT_AVATAR } from '../../constants'
import Avatar from '../../components/Avatar'
import { Row, Col } from '../../components/Grid'

const ProfileForm = (props) => {
  const { isLoading, profile, onSubmit } = props

  const [avatar, setAvatar] = useState(profile?.avatar || DEFAULT_AVATAR + profile?.name?.split(' ').pop()[0])
  const [name, setName] = useState(profile?.name || '')
  const [email, setEmail] = useState(profile?.email || '')

  useEffect(() => {
    // Update avatar link
    setAvatar(profile?.avatar || DEFAULT_AVATAR + profile?.name?.split(' ').pop()[0])
  }, [profile])

  const hasUnsavedChanges = name !== profile?.name || email !== profile?.email

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ name, email })
  }

  const handleChangeAvatar = () => {
    alert('Coming soon!')
  }

  return (
    <Container>
      <ProfileCard interactive={true}>
        <FormTitle className="text-center text-md-start">Personal Infomation</FormTitle>
        <Row gutter={24}>
          <Col sm={4}>
            <AvatarContainer className="mb-3 mb-md-0 mx-auto">
              <AvatarWrapper>
                <Avatar url={avatar} />
                <AvatarOverlay onClick={handleChangeAvatar}>
                  <Icon icon="cloud-upload" color="#FFF" className="me-2" />
                  Upload
                </AvatarOverlay>
              </AvatarWrapper>
            </AvatarContainer>
          </Col>
          <Col sm={8}>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup
                  id="name"
                  type="text"
                  leftIcon="user"
                  placeholder="Your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <InputGroup
                  id="email"
                  type="email"
                  leftIcon="envelope"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup style={{ textAlign: 'center' }}>
                <Button type="submit" intent="primary" disabled={isLoading || !hasUnsavedChanges}>
                  {isLoading ? <Spinner size={20} /> : 'Update'}
                </Button>
              </FormGroup>
            </form>
          </Col>
        </Row>
      </ProfileCard>
    </Container>
  )
}

ProfileForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ProfileForm
