import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from '../services/apiService'
import { messageUtils } from './helpers'

export const list = createAsyncThunk('coupon/list', async ({ keyword, status, sort, sortType, page, limit }) => {
  const response = await apiService.coupon.list({ keyword, status, sort, sortType, page, limit })
  return response
})

export const create = createAsyncThunk('coupon/create', async ({ name, description, discountValue }) => {
  const response = await apiService.coupon.create({ name, description, discountValue })
  return response
})

export const update = createAsyncThunk('coupon/update', async ({ name, description }) => {
  const response = await apiService.coupon.update({ name, description })
  return response
})

export const enable = createAsyncThunk('coupon/enable', async ({ id }) => {
  const response = await apiService.coupon.enable({ id })
  return response
})

export const disable = createAsyncThunk('coupon/disable', async ({ id }) => {
  const response = await apiService.coupon.disable({ id })
  return response
})

export const del = createAsyncThunk('coupon/del', async ({ id }) => {
  const response = await apiService.coupon.del({ id })
  return response
})

const initialState = {
  isLoading: false,
  coupons: [],
  totalDocs: 0,
  totalPages: 0,
  /** @type {{ [key: string]: import('./helpers').IStoreMessage }} */
  messages: {},
}

const pushMsgTo = messageUtils('coupon')

const couponSlice = createSlice({
  name: 'coupon',
  initialState: { ...initialState },
  reducers: {
    removeMsg: (state, action) => {
      delete state.messages[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder
      // ========== LIST ========== //
      .addCase(list.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(list.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status !== 'fail') {
          state.coupons = [...resp.docs]
          state.totalDocs = resp.totalDocs
          state.totalPages = resp.totalPages
        } else {
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(list.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
  },
})

export const selectIsLoading = (state) => state.coupon.isLoading
export const selectCoupons = (state) => state.coupon.coupons
export const selectTotalDocs = (state) => state.coupon.totalDocs
export const selectTotalPages = (state) => state.coupon.totalPages

export const { removeMsg } = couponSlice.actions
export default couponSlice.reducer
