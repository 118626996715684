import React, { useEffect, useState } from 'react'
import { Button, Card, FormGroup, InputGroup, MenuItem } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import { Select2 } from '@blueprintjs/select'
import { Row, Col } from '../../../../components/Grid'
import { COUPON } from '../../../../constants'

const FilterForm = (props) => {
  const { onFilter } = props
  const [keyword, setKeyword] = useState('')
  const [status, setStatus] = useState(COUPON.STATUS[0])
  const [sortParams, setSortParams] = useState(COUPON.SORT_OPTIONS[0])

  useEffect(() => {
    const queryParams = { page: 1 } // Any filter change must set page back to 1
    if (keyword) queryParams.keyword = keyword
    if (status?.value) queryParams.status = status.value
    if (sortParams) {
      const [sort, sortType] = sortParams.value.split('-')
      queryParams.sort = sort
      queryParams.sortType = sortType
    }
    onFilter(queryParams)
  }, [keyword, status, sortParams])

  const itemRenderer = (_sortOption, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={_sortOption.value}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={_sortOption.title}
      />
    )
  }

  return (
    <Card>
      <Row>
        <Col auto>
          <FormGroup label="Keyword" labelFor="keyword" inline>
            <InputGroup
              id="keyword"
              type="text"
              rightIcon="search"
              placeholder="Search by name or code"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              required
            />
          </FormGroup>
        </Col>
        <Col auto>
          <FormGroup label="Status" inline>
            <Select2
              items={COUPON.STATUS}
              fill
              filterable={false}
              itemRenderer={itemRenderer}
              onItemSelect={setStatus}
            >
              <Button text={status?.title || 'Select one...'} rightIcon="chevron-down" />
            </Select2>
          </FormGroup>
        </Col>
        <Col auto>
          <FormGroup label="Sort by" inline>
            <Select2
              items={COUPON.SORT_OPTIONS}
              fill
              filterable={false}
              itemRenderer={itemRenderer}
              onItemSelect={setSortParams}
            >
              <Button text={sortParams.title} rightIcon="chevron-down" />
            </Select2>
          </FormGroup>
        </Col>
      </Row>
    </Card>
  )
}

FilterForm.propTypes = {
  onFilter: PropTypes.func.isRequired,
}

export default FilterForm