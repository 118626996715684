import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Cell, Column, Table2 } from '@blueprintjs/table'
import { COUPON } from '../../../../constants'

const Table = (props) => {
  const { coupons } = props

  const nameCellRenderer = (rowInd) => <Cell>{coupons[rowInd].name}</Cell>
  const codeCellRenderer = (rowInd) => <Cell>{coupons[rowInd].code}</Cell>
  const discountCellRenderer = (rowInd) => {
    const { discountType, discountValue } = coupons[rowInd]
    const suffix = discountType === COUPON.DISCOUNT_TYPE.PERCENTAGE ? '%' : ''
    return <Cell>{discountValue + suffix}</Cell>
  }
  const statusCellRenderer = (rowInd) => {
    const status = COUPON.STATUS.find(s => s.value === coupons[rowInd].status)
    return <Cell>{status.title}</Cell>
  }
  const createdAtCellRenderer = (rowInd) => {
    const formattedDate = moment(coupons[rowInd].createdAt).format('DD/MM/YYYY')
    return <Cell>{formattedDate}</Cell>
  }

  return (
    <Table2 numRows={coupons.length} cellRendererDependencies={[coupons]}>
      <Column name="Name" cellRenderer={nameCellRenderer} />
      <Column name="Code" cellRenderer={codeCellRenderer} />
      <Column name="Discount" cellRenderer={discountCellRenderer} />
      <Column name="Status" cellRenderer={statusCellRenderer} />
      <Column name="Created at" cellRenderer={createdAtCellRenderer} />
    </Table2>
  )
}

Table.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Table
