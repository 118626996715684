import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ChangePassContainer from '../pages/auth/change-password'
import ForgotPassContainer from '../pages/auth/forgot-password'
import LoginContainer from '../pages/auth/login'
import RegisterContainer from '../pages/auth/register'
import ResetPassContainer from '../pages/auth/reset-password'
import CouponManagementContainer from '../pages/coupon'
import HomeContainer from '../pages/home'
import ProfileContainer from '../pages/profile'
import { ROUTES } from './define'

const AppRouter = () => (
  <Routes>
    <Route path={ROUTES.REGISTER} element={<RegisterContainer />} />
    <Route path={ROUTES.LOGIN} element={<LoginContainer />} />
    <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassContainer />} />
    <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassContainer />} />
    <Route path={ROUTES.HOME} element={<HomeContainer />} />
    <Route path={ROUTES.PROFILE} element={<ProfileContainer />} />
    <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassContainer />} />
    <Route path={ROUTES.COUPON} element={<CouponManagementContainer />} />
  </Routes>
)

export default AppRouter
