import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AuthLayout from '../../../layouts/authentication'
import { register, selectIsLoading } from '../../../stores/auth'
import RegisterForm from './RegisterForm'

const RegisterContainer = () => {
  const isLoading = useSelector(selectIsLoading)
  const dispatch = useDispatch()

  const onSubmit = (payload) => dispatch(register(payload))

  return (
    <AuthLayout>
      <RegisterForm isLoading={isLoading} onSubmit={onSubmit} />
    </AuthLayout>
  )
}

export default RegisterContainer
