import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectIsAuth, selectIsCheckingAuth } from '../stores/auth'
import { ROUTES } from '../routes/define'

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AuthLayout = ({ children }) => {
  const isCheckingAuth = useSelector(selectIsCheckingAuth)
  const isAuth = useSelector(selectIsAuth)
  const navigate = useNavigate()

  useEffect(() => !isCheckingAuth && isAuth && navigate(ROUTES.HOME), [isCheckingAuth, isAuth])

  return <Container>{children}</Container>
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ])
}

export default AuthLayout
