import styled from 'styled-components'

export const FormTitle = styled.h2`
  text-align: center;
`

export const HelperLink = styled.a`
  display: block;
  text-align: center;
`
