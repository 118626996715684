import React from 'react'
import { Card } from '@blueprintjs/core'
import { FormTitle, HelperLink } from './ForgotPassForm.styled'
import { ROUTES } from '../../../routes/define'

const ForgotPassSent = () => {
  return (
    <Card interactive={true} style={{ minWidth: 320, maxWidth: 480, width: '25%' }}>
      <FormTitle>Forgot Password</FormTitle>
      <p>
        We have sent a password reset email to your email. Please check your inbox (do not forget to check your spam folder
        too). Please note: The password reset link is only valid for a short time.
      </p>
      <HelperLink href={ROUTES.LOGIN}>Go back</HelperLink>
    </Card>
  )
}

export default ForgotPassSent
