import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../../layouts/dashboard'
import { logout } from '../../../stores/auth'
import { resetState, selectIsLoading, selectIsPasswordUpdated, updatePassword } from '../../../stores/profile'
import ChangePassForm from './ChangePassForm'

const ChangePassContainer = () => {
  const isLoading = useSelector(selectIsLoading)
  const isPasswordUpdated = useSelector(selectIsPasswordUpdated)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isPasswordUpdated) {
      // Reset profile state
      dispatch(resetState())
      // Force user logout
      dispatch(logout())
    }
  }, [isPasswordUpdated])

  const onSubmit = (payload) => dispatch(updatePassword(payload))

  return (
    <DashboardLayout>
      <ChangePassForm isLoading={isLoading} onSubmit={onSubmit} />
    </DashboardLayout>
  )
}

export default ChangePassContainer
