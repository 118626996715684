import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from '../services/apiService'
import { messageUtils } from './helpers'

export const checkAuth = createAsyncThunk('auth/getProfile', async () => {
  const response = await apiService.profile.getProfile()
  return response
})

export const register = createAsyncThunk(
  'auth/register',
  async ({ name, email, password, passwordConfirm, role }) => {
    const response = await apiService.auth.register({ name, email, password, passwordConfirm, role })
    return response
  }
)

export const login = createAsyncThunk('auth/login', async ({ email, password }) => {
  const response = await apiService.auth.login({ email, password })
  return response
})

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async ({ email }) => {
  const response = await apiService.auth.forgotPassword({ email })
  return response
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async ({ token, password, passwordConfirm }) => {
  const response = await apiService.auth.resetPassword({ token, password, passwordConfirm })
  return response
})

const initialState = {
  accessToken: apiService.token.get(),
  isAuth: false,
  isLoading: false,
  isCheckingAuth: false,
  isForgotPassEmailSent: null,
  isPasswordReset: null,
  /** @type {{ [key: string]: import('./helpers').IStoreMessage }} */
  messages: {},
}

const pushMsgTo = messageUtils('auth')

const authSlice = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    logout: () => {
      apiService.token.del()
      const messages = {}
      pushMsgTo(messages).info('You has been logged out')
      const newState = { ...initialState }
      newState.accessToken = null
      newState.messages = messages
      return newState
    },
    resetForgotPasswordFlag: (state) => {
      state.isForgotPassEmailSent = null
    },
    resetPasswordResetFlag: (state) => {
      state.isPasswordReset = null
    },
    removeMsg: (state, action) => {
      delete state.messages[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder
      // ========== CHECK AUTH ========== //
      .addCase(checkAuth.pending.type, (state) => {
        state.isCheckingAuth = true
      })
      .addCase(checkAuth.fulfilled.type, (state, action) => {
        state.isCheckingAuth = false
        const resp = action.payload
        if (resp.status === 'success') {
          state.isAuth = true
        } else {
          state.accessToken = null
          state.isAuth = false
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(checkAuth.rejected.type, (state, action) => {
        state.isCheckingAuth = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
      // ========== REGISTER ========== //
      .addCase(register.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          apiService.token.save(resp.data.token)
          state.accessToken = resp.data.token
          state.isAuth = true
          pushMsgTo(state.messages).success(`Welcome to VersaVid, ${resp.data.data.name}!`)
        } else {
          state.accessToken = null
          state.isAuth = false
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(register.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
      // ========== LOGIN ========== //
      .addCase(login.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          apiService.token.save(resp.data.token)
          state.accessToken = resp.data.token
          state.isAuth = true
          pushMsgTo(state.messages).success(`Welcome back, ${resp.data.user.name}!`)
        } else {
          state.accessToken = null
          state.isAuth = false
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(login.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
      // ========== FORGOT PASSWORD ========== //
      .addCase(forgotPassword.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(forgotPassword.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          state.isForgotPassEmailSent = true
        } else {
          state.isForgotPassEmailSent = null
          pushMsgTo(state.messages).error(action.payload.message)
        }
      })
      .addCase(forgotPassword.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
      // ========== RESET PASSWORD ========== //
      .addCase(resetPassword.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          state.isPasswordReset = true // Move user to login screen (in ResetPassContainer)
          pushMsgTo(state.messages).success('Your password has been reset! Please login again!')
        } else {
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(resetPassword.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
  },
})

export const selectAccessToken = (state) => state.auth.accessToken
export const selectIsAuth = (state) => state.auth.isAuth
export const selectIsCheckingAuth = (state) => state.auth.isCheckingAuth
export const selectIsLoading = (state) => state.auth.isLoading
export const selectIsForgotPassEmailSent = (state) => state.auth.isForgotPassEmailSent
export const selectIsPasswordReset = (state) => state.auth.isPasswordReset

export const { logout, resetForgotPasswordFlag, resetPasswordResetFlag, removeMsg } = authSlice.actions
export default authSlice.reducer
