import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiService from '../services/apiService'
import { messageUtils } from './helpers'

export const getProfile = createAsyncThunk('profile/getProfile', async () => {
  const response = await apiService.profile.getProfile()
  return response
})

export const updateProfile = createAsyncThunk('profile/updateProfile', async ({ name, email }) => {
  const response = await apiService.profile.updateProfile({ name, email })
  return response
})

export const updatePassword = createAsyncThunk(
  'profile/updatePassword',
  async ({ passwordCurrent, password, passwordConfirm }) => {
    const response = await apiService.profile.updatePassword({ passwordCurrent, password, passwordConfirm })
    return response
  }
)

const initialState = {
  user: null,
  isLoading: false,
  isPasswordUpdated: false,
  /** @type {{ [key: string]: import('./helpers').IStoreMessage }} */
  messages: {},
}

const pushMsgTo = messageUtils('profile')

const profileSlice = createSlice({
  name: 'profile',
  initialState: { ...initialState },
  reducers: {
    setProfile: (state, action) => {
      state.user = action.payload
    },
    removeMsg: (state, action) => {
      delete state.messages[action.payload]
    },
    resetState: () => {
      const newState = { ...initialState }
      return newState
    },
  },
  extraReducers: (builder) => {
    builder
      // ========== GET PROFILE ========== //
      .addCase(getProfile.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          state.user = resp.data.data
        } else {
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(getProfile.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
      // ========== UPDATE PROFILE ========== //
      .addCase(updateProfile.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(updateProfile.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          state.user = resp.data.user
          pushMsgTo(state.messages).success('Your profile has been updated!')
        } else {
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(updateProfile.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
      // ========== UPDATE PASSWORD ========== //
      .addCase(updatePassword.pending.type, (state) => {
        state.isLoading = true
      })
      .addCase(updatePassword.fulfilled.type, (state, action) => {
        state.isLoading = false
        const resp = action.payload
        if (resp.status === 'success') {
          state.isPasswordUpdated = true // Force user logout (in ChangePassContainer)
          pushMsgTo(state.messages).success('Your password has been updated! Please login again!')
        } else {
          pushMsgTo(state.messages).error(resp.message)
        }
      })
      .addCase(updatePassword.rejected.type, (state, action) => {
        state.isLoading = false
        const error = action.error
        console.error(error)
        pushMsgTo(state.messages).error(error.message)
      })
  },
})

export const selectProfile = (state) => state.profile.user
export const selectIsLoading = (state) => state.profile.isLoading
export const selectIsPasswordUpdated = (state) => state.profile.isPasswordUpdated

export const { setProfile, removeMsg, resetState } = profileSlice.actions
export default profileSlice.reducer
