import axios from 'axios'
import { LS_ACCESS_TOKEN } from '../constants'

// Set config defaults when creating the instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem(LS_ACCESS_TOKEN)
  config.headers.Authorization = `Bearer ${token}`
  return config
})

export default api
