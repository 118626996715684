import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardLayout from '../../layouts/dashboard'
import { list, selectCoupons, selectTotalDocs, selectTotalPages } from '../../stores/coupon'
import CouponManagement from './CouponManagement'

const CouponManagementContainer = () => {
  const coupons = useSelector(selectCoupons)
  const totalDocs = useSelector(selectTotalDocs)
  const totalPages = useSelector(selectTotalPages)
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [queryParams, setQueryParams] = useState({})

  useEffect(() => {
    dispatch(list({ page, limit,  ...queryParams }))
  }, [page, limit, queryParams])
  
  return (
    <DashboardLayout>
      <CouponManagement
        coupons={coupons}
        page={page}
        limit={limit}
        totalDocs={totalDocs}
        totalPages={totalPages}
        onPageChange={setPage}
        onLimitChange={setLimit}
        onFilter={setQueryParams}
      />
    </DashboardLayout>
  )
}

export default CouponManagementContainer
