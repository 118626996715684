export const ROUTES = Object.freeze({
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  HOME: '/',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  COUPON: '/coupons',
  TRANSACTION: '/transactions',
  USER: '/users',
})
