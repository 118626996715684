import React from 'react'
import PropTypes from 'prop-types'
import StylePropType from 'react-style-proptype'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: inline-block;
  width: ${(props) => props.size ? props.size + 'px' : '100%'};
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 0 0px 2px #2d72d2;
  overflow: hidden;
`

const Avatar = (props) => {
  const { url, size, style = {}, imgStyle = {}, className } = props

  if (!imgStyle.objectFit) imgStyle.objectFit = 'cover'
  imgStyle.aspectRatio = 1

  return (
    <Wrapper size={size} style={style} className={className}>
      <img src={url} width="100%" style={imgStyle} alt="avatar" />
    </Wrapper>
  )
}

Avatar.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: StylePropType,
  imgStyle: StylePropType,
  className: PropTypes.string,
}

export default Avatar
