import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AppRouter from './routes'
import { checkAuth, selectIsAuth, selectIsCheckingAuth } from './stores/auth'
import { selectAccessToken } from './stores/auth'
import ToastMessage from './ToastMessage'
import SplashScreen from './layouts/splash'
import { getProfile } from './stores/profile'

const App = () => {
  const isCheckingAuth = useSelector(selectIsCheckingAuth)
  const isAuth = useSelector(selectIsAuth)
  const accessToken = useSelector(selectAccessToken)
  const dispatch = useDispatch()

  useEffect(() => {
    if (accessToken) {
      dispatch(checkAuth())
    }
  }, [accessToken])

  useEffect(() => {
    if (isAuth) {
      dispatch(getProfile())
    }
  }, [isAuth])

  if (isCheckingAuth) {
    return <SplashScreen />
  }

  return (
    <>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <ToastMessage />
    </>
  )
}

export default App
