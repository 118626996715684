import { LS_ACCESS_TOKEN } from '../../constants'

const save = (token) => {
  localStorage.setItem(LS_ACCESS_TOKEN, token)
}

const del = () => {
  localStorage.removeItem(LS_ACCESS_TOKEN)
}

const get = () => {
  return localStorage.getItem(LS_ACCESS_TOKEN)
}

const token = { save, del, get }
export default token