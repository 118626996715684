import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, FormGroup, InputGroup, Button, Spinner } from '@blueprintjs/core'
import { FormTitle, HelperLink } from './ForgotPassForm.styled'
import { ROUTES } from '../../../routes/define'

const ForgotPassForm = (props) => {
  const { isLoading, onSubmit } = props
  const [email, setEmail] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ email })
  }

  return (
    <Card interactive={true} style={{ minWidth: 320, maxWidth: 480, width: '25%' }}>
      <FormTitle>Forgot Password</FormTitle>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup
            id="email"
            type="email"
            leftIcon="envelope"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup style={{ textAlign: 'center' }}>
          <Button type="submit" intent="primary" disabled={isLoading}>
            {isLoading ? <Spinner size={20} /> : 'Reset My Password'}
          </Button>
        </FormGroup>
        <HelperLink href={ROUTES.REGISTER} disabled={isLoading}>
          Not have any account yet? Register now!
        </HelperLink>
      </form>
    </Card>
  )
}

ForgotPassForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ForgotPassForm
