import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, FormGroup, InputGroup, Button, Spinner } from '@blueprintjs/core'
import { Container, FormTitle } from './ChangePassForm.styled'

const ChangePassForm = (props) => {
  const { isLoading, onSubmit } = props

  const [passwordCurrent, setPasswordCurrent] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ passwordCurrent, password, passwordConfirm })
  }

  return (
    <Container>
      <Card interactive={true} style={{ minWidth: 320, maxWidth: 480 }}>
        <FormTitle>Change Password</FormTitle>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <InputGroup
              id="passwordCurrent"
              type="password"
              leftIcon="lock"
              placeholder="Your current password"
              value={passwordCurrent}
              onChange={(e) => setPasswordCurrent(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <InputGroup
              id="password"
              type="password"
              leftIcon="lock"
              placeholder="Your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <InputGroup
              id="passwordConfirm"
              type="password"
              leftIcon="lock"
              placeholder="Confirm new password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup style={{ textAlign: 'center' }}>
            <Button type="submit" intent="primary" disabled={isLoading}>
              {isLoading ? <Spinner size={20} /> : 'Submit'}
            </Button>
          </FormGroup>
        </form>
      </Card>
    </Container>
  )
}

ChangePassForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ChangePassForm
