import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AuthLayout from '../../../layouts/authentication'
import { login, selectIsLoading } from '../../../stores/auth'
import LoginForm from './LoginForm'

const LoginContainer = () => {
  const isLoading = useSelector(selectIsLoading)
  const dispatch = useDispatch()

  const onSubmit = (payload) => dispatch(login(payload))

  return (
    <AuthLayout>
      <LoginForm isLoading={isLoading} onSubmit={onSubmit} />
    </AuthLayout>
  )
}

export default LoginContainer
