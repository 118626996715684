import { Alignment, Button, Icon, MenuItem, Navbar, Text } from '@blueprintjs/core'
import { Select2 } from '@blueprintjs/select'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Avatar from '../components/Avatar'
import { selectProfile } from '../stores/profile'
import { logout, selectIsAuth, selectIsCheckingAuth } from '../stores/auth'
import { DEFAULT_AVATAR } from '../constants'
import { ROUTES } from '../routes/define'
import Logo from '../components/Logo'

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const Body = styled.section`
  flex-grow: 1;
  overflow-y: scroll;
  padding: 24px;
`

const UserMenu = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`

const UserInfo = styled.div`
  max-width: 128px;
  margin-right: 8px;
`

const MENU_ITEMS = [
  { icon: 'bank-account', label: 'Wallet', action: 'wallet' },
  { icon: 'user', label: 'Profile', action: 'profile' },
  { icon: 'lock', label: 'Change password', action: 'password' },
  { icon: 'log-out', label: 'Logout', action: 'logout' },
]

const DashboardLayout = ({ children }) => {
  const isCheckingAuth = useSelector(selectIsCheckingAuth)
  const isAuth = useSelector(selectIsAuth)
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => !isCheckingAuth && !isAuth && navigate(ROUTES.LOGIN), [isCheckingAuth, isAuth])

  const { email, name } = profile || {}
  const avatar = profile?.avatar || DEFAULT_AVATAR + name?.split(' ').pop()[0]

  const itemRenderer = (item, { handleClick, handleFocus }) => {
    return (
      <MenuItem
        icon={item.icon}
        text={item.label}
        roleStructure="listoption"
        active={false}
        key={item.action}
        onClick={handleClick}
        onFocus={handleFocus}
      />
    )
  }

  const onItemSelect = (item) => {
    const { action } = item

    if (action === 'wallet') {
      return navigate(ROUTES.TRANSACTION)
    }

    if (action === 'profile') {
      return navigate(ROUTES.PROFILE)
    }

    if (action === 'password') {
      return navigate(ROUTES.CHANGE_PASSWORD)
    }

    if (action === 'logout') {
      return dispatch(logout())
    }
  }

  return (
    <Container>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <Logo width={30} />
          </Navbar.Heading>
          <Navbar.Divider />
          <Button className="bp4-minimal" icon="home" onClick={() => navigate(ROUTES.HOME)}>
            <span className="d-none d-sm-block">Home</span>
          </Button>
          <Button className="bp4-minimal" icon="percentage" onClick={() => navigate(ROUTES.COUPON)}>
            <span className="d-none d-sm-block">Coupon</span>
          </Button>
          <Button className="bp4-minimal" icon="user" onClick={() => navigate(ROUTES.USER)}>
            <span className="d-none d-sm-block">User</span>
          </Button>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Select2 items={MENU_ITEMS} filterable={false} itemRenderer={itemRenderer} onItemSelect={onItemSelect}>
            <UserMenu>
              <Avatar url={avatar} size={32} className="me-2" />
              <UserInfo className="d-none d-sm-block">
                <Text ellipsize title={name}>
                  {name}
                </Text>
                <Text ellipsize title={email} className="bp4-text-small bp4-text-muted">
                  {email}
                </Text>
              </UserInfo>
              <Icon icon="chevron-down" />
            </UserMenu>
          </Select2>
        </Navbar.Group>
      </Navbar>
      <Body>{children}</Body>
    </Container>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default DashboardLayout
