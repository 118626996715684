import styled from 'styled-components'

const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

const Col = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  ${({ xs }) => !xs ? null : `
    @media (min-width: ${BREAKPOINTS.xs}px) {
      flex: 0 0 ${(xs / 12) * 100}%;
      max-width: ${(xs / 12) * 100}%;
    }
  `}

  ${({ sm }) => !sm ? null : `
    @media (min-width: ${BREAKPOINTS.sm}px) {
      flex: 0 0 ${(sm / 12) * 100}%;
      max-width: ${(sm / 12) * 100}%;
    }
  `}

  ${({ md }) => !md ? null : `
    @media (min-width: ${BREAKPOINTS.md}px) {
      flex: 0 0 ${(md / 12) * 100}%;
      max-width: ${(md / 12) * 100}%;
    }
  `}

  ${({ lg }) => !lg ? null : `
    @media (min-width: ${BREAKPOINTS.lg}px) {
      flex: 0 0 ${(lg / 12) * 100}%;
      max-width: ${(lg / 12) * 100}%;
    }
  `}

  ${({ xl }) => !xl ? null : `
    @media (min-width: ${BREAKPOINTS.xl}px) {
      flex: 0 0 ${(xl / 12) * 100}%;
      max-width: ${(xl / 12) * 100}%;
    }
  `}

  ${({ xxl }) => !xxl ? null : `
    @media (min-width: ${BREAKPOINTS.xxl}px) {
      flex: 0 0 ${(xxl / 12) * 100}%;
      max-width: ${(xxl / 12) * 100}%;
    }
  `}
`

export { Row, Col }
