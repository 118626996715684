import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AuthLayout from '../../../layouts/authentication'
import { forgotPassword, resetForgotPasswordFlag, selectIsForgotPassEmailSent, selectIsLoading } from '../../../stores/auth'
import ForgotPassForm from './ForgotPassForm'
import ForgotPassSent from './ForgotPassSent'

const ForgotPassContainer = () => {
  const isLoading = useSelector(selectIsLoading)
  const isForgotPassEmailSent = useSelector(selectIsForgotPassEmailSent)
  const dispatch = useDispatch()

  const [isSent, setIsSent] = useState(false)

  useEffect(() => {
    if (isForgotPassEmailSent === true) {
      setIsSent(true)
      dispatch(resetForgotPasswordFlag())
    }
  }, [isForgotPassEmailSent])

  const onSubmit = (payload) => dispatch(forgotPassword(payload))

  return (
    <AuthLayout>
      {!isSent && <ForgotPassForm isLoading={isLoading} onSubmit={onSubmit} />}
      {isSent && <ForgotPassSent />}
    </AuthLayout>
  )
}

export default ForgotPassContainer
