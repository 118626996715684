import React from 'react'
import PropTypes from 'prop-types'
import FilterContainer from './components/Filter'
import TableContainer from './components/Table'

const CouponManagement = (props) => {
  const { coupons, page, limit, totalDocs, totalPages, onFilter } = props
  return (
    <>
      <FilterContainer onFilter={onFilter} />
      <TableContainer
        coupons={coupons}
        page={page}
        limit={limit}
        totalDocs={totalDocs}
        totalPages={totalPages}
      />
    </>
  )
}

CouponManagement.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalDocs: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default CouponManagement
