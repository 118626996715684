import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, FormGroup, InputGroup, Button, Spinner, MenuItem } from '@blueprintjs/core'
import { FormTitle, HelperLink } from './RegisterForm.styled'
import { Select2 } from '@blueprintjs/select'
import { ROLES } from '../../../constants'
import { ROUTES } from '../../../routes/define'

const RegisterForm = (props) => {
  const { isLoading, onSubmit } = props
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [role, setRole] = useState(ROLES[0])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ name, email, password, passwordConfirm, role: role.value })
  }

  const itemRenderer = (_role, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={_role.value}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={_role.title}
      />
    )
  }

  return (
    <Card interactive={true} style={{ minWidth: 320, maxWidth: 480, width: '25%' }}>
      <FormTitle>Join VersaVid as a Partner</FormTitle>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup
            id="name"
            type="text"
            leftIcon="person"
            placeholder="Your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <InputGroup
            id="email"
            type="email"
            leftIcon="envelope"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <InputGroup
            id="password"
            type="password"
            leftIcon="lock"
            placeholder="Your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <InputGroup
            id="passwordConfirm"
            type="password"
            leftIcon="lock"
            placeholder="Confirm your password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup label="Register as" inline>
          <Select2
            items={ROLES}
            fill
            filterable={false}
            popoverProps={{ minimal: false }}
            itemRenderer={itemRenderer}
            onItemSelect={setRole}
          >
            <Button text={role.title} outlined rightIcon="chevron-down" />
          </Select2>
        </FormGroup>
        <FormGroup style={{ textAlign: 'center' }}>
          <Button type="submit" intent="primary" disabled={isLoading}>
            {isLoading ? <Spinner size={20} /> : 'Register'}
          </Button>
        </FormGroup>
        <HelperLink href={ROUTES.LOGIN} disabled={isLoading}>
          Already have an account? Login now!
        </HelperLink>
        <HelperLink href={ROUTES.FORGOT_PASSWORD} disabled={isLoading}>
          Forgot password?
        </HelperLink>
      </form>
    </Card>
  )
}

RegisterForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default RegisterForm
