import React from 'react'
import PropTypes from 'prop-types'
import FilterForm from './FilterForm'

const FilterContainer = (props) => {
  return <FilterForm onFilter={props.onFilter} />
}

FilterContainer.propTypes = {
  onFilter: PropTypes.func.isRequired,
}

export default FilterContainer
