import { v4 as uuidv4 } from 'uuid'

/**
 * @typedef {'auth' | 'profile' | 'coupon'} EStoreName
 * @typedef {'info' | 'success' | 'error'} EMessageType
 * 
 * @typedef {Object} IStoreMessage
 * @prop {string} id
 * @prop {EStoreName} store
 * @prop {EMessageType} type
 * @prop {string} message
 */

/**
 * Generate a store message
 * @param {EStoreName} store Redux Store name
 * @param {EMessageType} type Type of message
 * @param {string} message Message to show
 * @returns {IStoreMessage} Store Message
 */
const genMessage = (store, type, message) => ({ id: uuidv4(), type, message, store })

/**
 * Gen a message utils for a store
 * @param {EStoreName} store Name of Redux store
 * @returns 
 */
export const messageUtils = (store) =>
  /**
   * @param {{ [key: string]: IStoreMessage }} messages The state to push message in
   * @returns 
   */
  (messages) => ({
    /**
     * Push an info message to the store
     * @param {string} message Message to show
     */
    info: (message) => {
      const msg = genMessage(store, 'info', message)
      messages[msg.id] = msg
      return messages
    },
    /**
     * Push a success message to the store
     * @param {string} message Message to show
     */
    success: (message) => {
      const msg = genMessage(store, 'success', message)
      messages[msg.id] = msg
      return messages
    },
    /**
     * Push an error message to the store
     * @param {string} message Message to show
     */
    error: (message) => {
      const msg = genMessage(store, 'error', message)
      messages[msg.id] = msg
      return messages
    },
  })