import { removeMsg as removeMsgAuth } from './auth'
import { removeMsg as removeMsgProfile } from './profile'
import { removeMsg as removeMsgCoupon } from './coupon'

/** @type {(state: any) => { [key: string]: { id: string, icon: string, intent: string, message: string, clearAction: Function } }} */
export const selectMsgs = (state) =>
  Object
    // Get all message of all stores
    .values({
      ...state.auth.messages,
      ...state.profile.messages,
      ...state.coupon.messages,
    })
    // Only get message has value
    .filter((msg) => !!msg)
    // Transform message data in to Toast data
    .reduce((messages, msg) => {
      /** @type {import('./helpers').IStoreMessage} */
      const { id, store, type, message } = msg

      const icon = {
        info: 'info-sign',
        success: 'tick-circle',
        error: 'error',
      }[type]

      const intent = {
        info: 'primary',
        success: 'success',
        error: 'danger',
      }[type]

      const clearAction = {
        auth: () => removeMsgAuth(id),
        profile: () => removeMsgProfile(id),
        coupon: () => removeMsgCoupon(id),
      }[store]

      messages[id] = { id, icon, intent, message, clearAction }
      return messages
    }, {})
