/** Key of access token save in `localStorage` */
export const LS_ACCESS_TOKEN = 'vvAccessToken'

/** Supported partner roles in our system */
export const ROLES = [
  { title: 'KOL', value: 'kol' },
  { title: 'Youtuber', value: 'youtuber' },
  { title: 'Streamer', value: 'streamer' },
]

/** Default user avatar */
export const DEFAULT_AVATAR = 'https://via.placeholder.com/128?text='

export const COUPON = Object.freeze({
  STATUS: [
    { title: 'All', value: '' },
    { title: 'Enabled', value: 'enabled' },
    { title: 'Disabled', value: 'disabled' },
  ],
  DISCOUNT_TYPE: Object.freeze({
    /** Discount a fixed amount of money */
    FIXED: 'fixed',
    /** Discount a percent of total amount */
    PERCENTAGE: 'percentage',
  }),
  SORT_OPTIONS: [
    { title: 'Newest', value: 'createdAt-DESC' },
    { title: 'Oldest', value: 'createdAt-ASC' },
    { title: 'Name A-Z', value: 'name-ASC' },
    { title: 'Name Z-A', value: 'name-DESC' },
    { title: 'Discount smallest first', value: 'discountValue-ASC' },
    { title: 'Discount greatest first', value: 'discountValue-DESC' },
  ],
})