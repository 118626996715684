import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AuthLayout from '../../../layouts/authentication'
import { ROUTES } from '../../../routes/define'
import { resetPassword, selectIsLoading, selectIsPasswordReset, resetPasswordResetFlag } from '../../../stores/auth'
import ResetPassForm from './ResetPassForm'

const ResetPassContainer = () => {
  const [queryParams] = useSearchParams()
  const isLoading = useSelector(selectIsLoading)
  const isPasswordReset = useSelector(selectIsPasswordReset)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const token = queryParams.get('token')

  useEffect(() => {
    if (!token) navigate(ROUTES.FORGOT_PASSWORD)
  }, [token])

  useEffect(() => {
    if (isPasswordReset) {
      // Reset state
      dispatch(resetPasswordResetFlag())
      // Push to login screen
      navigate(ROUTES.LOGIN)
    }
  }, [isPasswordReset])

  const onSubmit = (payload) => dispatch(resetPassword({ token, ...payload }))

  return (
    <AuthLayout>
      <ResetPassForm isLoading={isLoading} onSubmit={onSubmit} />
    </AuthLayout>
  )
}

export default ResetPassContainer
