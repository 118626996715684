import api from '../axios'

const list = async ({ keyword, status, sort, sortType, page, limit }) => {
  try {
    const params = { keyword, status, sort, sortType, page, limit }
    const resp = await api.get('/coupon/list', { params })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const create = async ({ name, description, discountValue }) => {
  try {
    const resp = await api.post('/coupon', { name, description, discountValue })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const update = async ({ id, name, description }) => {
  try {
    const resp = await api.patch('/coupon/' + id, { name, description })
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const enable = async ({ id }) => {
  try {
    const resp = await api.patch(`/coupon/${id}/enable`)
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const disable = async ({ id }) => {
  try {
    const resp = await api.patch(`/coupon/${id}/disable`)
    return resp.data
  } catch (error) {
    return error.response.data
  }
}
const del = async ({ id }) => {
  try {
    const resp = await api.delete('/coupon/' + id)
    return resp.data
  } catch (error) {
    return error.response.data
  }
}

const coupon = { list, create, update, enable, disable, del }
export default coupon
