import { Toaster } from '@blueprintjs/core'
import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMsgs } from './stores/global'

const ToastMessage = () => {
  const messages = useSelector(selectMsgs)
  const dispatch = useDispatch()

  /** @type {React.MutableRefObject<Map<string, boolean>>} */
  const showingIds = useRef(new Map())

  /** @type {React.MutableRefObject<Toaster>} */
  const toasterRef = useRef()

  const unshowMsgs = useMemo(() => {
    const unshow = { ...messages }
    showingIds.current.forEach((_, id) => delete unshow[id])
    return unshow
  }, [messages])

  useEffect(() => {
    (async () => {
      for (const msgId in unshowMsgs) {
        console.log('Show message', msgId)
        const { id, icon, intent, message, clearAction } = unshowMsgs[msgId]
        toasterRef.current.show({
          icon,
          intent,
          message,
          onDismiss: () => {
            dispatch(clearAction())
            showingIds.current.delete(id)
          },
        })
        showingIds.current.set(id, true)
        await new Promise((resolve) => setTimeout(resolve, 500))
      }
    })()
  }, [unshowMsgs, dispatch])

  return <Toaster ref={toasterRef} />
}

export default ToastMessage
