import { Card } from '@blueprintjs/core'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px 0;
`

export const ProfileCard = styled(Card)`
  width: 100%;

  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 60%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
  @media (min-width: 1200px) {
    width: 40%;
  }
  @media (min-width: 1400px) {
    width: 33.33%;
  }
`

export const FormTitle = styled.h2`
  margin-bottom: 24px;
`

export const HelperLink = styled.a`
  display: block;
  text-align: center;
`

export const FormLayout = {
  Row: styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 -8px;
  `,
  ColAvatar: styled.div`
    width: 160px;
    padding: 8px;
  `,
  ColInfo: styled.div`
    width: 320px;
    padding: 8px;
  `,
}

export const AvatarContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 128px;
  padding-top: min(100%, 128px);
`

export const AvatarWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

export const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity .35s;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`
