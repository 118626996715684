import React from 'react'
import PropTypes from 'prop-types'
import Table from './Table'

const TableContainer = (props) => {
  const { coupons } = props
  if (coupons.length === 0) {
    return <p className="w-md-50 mx-md-auto py-5 text-center">You do not have any coupon yet. Try create a new one!</p>
  }
  return <Table coupons={coupons} />
}

TableContainer.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalDocs: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default TableContainer
